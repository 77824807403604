import React, { FC, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  Divider,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { observer } from 'mobx-react-lite';

import infoSectionStore from 'store/infoSectionsStore';
import globalState from 'store/globalState';
import categories from './SidebarMenu.data';

const sidebarState = () => {
  const state: Array<boolean> = JSON.parse(
    localStorage.getItem('sidebarState') || '[false, false, false]'
  );
  return state;
};

const SidebarMenu: FC = () => {
  const { infoSections } = infoSectionStore;
  const { gitInfo, buildInfo } = globalState;
  const [open, setOpen] = useState<Array<boolean>>(sidebarState());

  useEffect(() => {
    infoSectionStore.getAllInfoSections();
  }, []);

  const handleClick = useCallback(
    (id: number) => () => {
      setOpen((openOld) => {
        const newArr = [...openOld];
        newArr[id] = !newArr[id];
        localStorage.setItem('sidebarState', JSON.stringify(newArr));

        return newArr;
      });
    },
    [infoSections]
  );

  const navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: '300px',
        position: 'relative',
        flexShrink: 0,
        bottom: '0',
        top: 'auto',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': {
          height: 'calc(100vh - 64px)',
          bottom: '0',
          top: 'auto',
          width: '300px',
          boxSizing: 'border-box'
        }
      }}
    >
      <List component="div" disablePadding>
        {categories.map((elem, id) => {
          const title = (
            <ListItemButton onClick={handleClick(id)}>
              <ListItemText primary={elem.headerName} />
              {open[id] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          );

          const items = elem.children.map((child) => (
            <Collapse
              in={open[id]}
              timeout="auto"
              unmountOnExit
              key={child.key}
            >
              <ListItemButton sx={{ pl: 3 }} onClick={() => navigate(child.to)}>
                <ListItemText primary={child.name} />
              </ListItemButton>
            </Collapse>
          ));

          return (
            <Box key={elem.key}>
              {title}
              {items}
              <Divider />
            </Box>
          );
        })}
        <Box>
          <ListItemButton onClick={handleClick(1)}>
            <ListItemText primary="Контент" />
            {open[1] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {infoSections.map((section) => (
            <Collapse
              in={open[1]}
              timeout="auto"
              unmountOnExit
              key={section.id}
            >
              <ListItemButton
                sx={{ pl: 3 }}
                onClick={() =>
                  navigate(`/pages-from-infosection/${section.id}`)
                }
              >
                <ListItemText primary={section.name} />
              </ListItemButton>
            </Collapse>
          ))}
          <Divider />
        </Box>
        <Box>
          <ListItemButton onClick={handleClick(2)}>
            <ListItemText primary="Система" />
            {open[2] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open[2]} timeout="auto" unmountOnExit>
            <ListItemButton
              sx={{ pl: 3 }}
              onClick={() => navigate(`/sections`)}
            >
              <ListItemText primary="Инфосекции" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} onClick={() => navigate(`/seo`)}>
              <ListItemText primary="Seo" />
            </ListItemButton>
          </Collapse>
          <Divider />
        </Box>
      </List>
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          bottom: 30,
          left: 10,
          color: 'text.disabled'
        }}
      >
        {gitInfo.gitBranch} - #{gitInfo.gitCommitHash}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 10,
          color: 'text.disabled'
        }}
      >
        build {buildInfo.date}
      </Typography>
    </Drawer>
  );
};

export default observer(SidebarMenu);
