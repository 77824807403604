// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useCallback, useState, memo, useEffect } from 'react';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  IconButton,
  ListItem,
  List,
  ListItemText,
  Grid,
  Divider,
  ListItemButton,
  Stack
} from '@mui/material';
import ExpandIcon from '@mui/icons-material/Expand';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoveDownIcon from '@mui/icons-material/MoveDown';

import DialogModal from 'components/dialogModal';

import categoriesStore from 'store/categoriesStore';
import productsStore from 'store/productsStore';
import tagsStore from 'store/tagsStore';

import api from 'api';
import { isPaginated } from 'utilities/functions';

import { arrayMoveImmutable } from 'array-move';

import {
  IProduct,
  ListType,
  ITag,
  ICategory,
  EnumPath,
  IInfoSection,
  IInfoPage,
  ISeo
} from 'types/types';
import MoveToPageMenu from 'components/moveToPageMenu';
import infoSectionStore from 'store/infoSectionsStore';
import infoPagesStore from 'store/infoPagesStore';
import seoStore from '../../store/seoStore';

function isInstance<T>(object: any, properties: Array<keyof T>): object is T {
  return properties.every((prop) => typeof object[prop] !== 'undefined');
}

interface IProps {
  list: ListType<IProduct | ICategory | IInfoSection | IInfoPage | ITag | ISeo>;
  onReload(): void;
  notifyUser(message: string, status: 'error' | 'success' | 'warning'): void;
  isSortable?: boolean;
  pagesQuantity?: number;
  onClick?(id: number): () => void;
  sectionId?: number;
  path: EnumPath;
}

const DraggableList: FC<IProps> = ({
  list,
  onReload,
  notifyUser,
  isSortable,
  pagesQuantity,
  onClick,
  sectionId,
  path
}) => {
  const [inputList, setInputList] =
    useState<
      ListType<IProduct | ICategory | ISeo | IInfoSection | IInfoPage | ITag>
    >(list);
  // const [editDialogId, setEditDialogId] = useState<number | null>(null);
  const [deleteDialogId, setDeleteDialogId] = useState<number | null>(null);
  const [productToMove, setProductToMove] = useState<IProduct | ICategory>(
    list[0]
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [parentSectionSlug, setParentSectionSlug] = useState<string>('');

  const { infoSection } = infoSectionStore;

  const navigate = useNavigate();
  const location = useLocation();

  const onDrop = useCallback(
    ({ removedIndex, addedIndex }: DropResult) => {
      if (removedIndex !== null && addedIndex !== null && isSortable) {
        if (
          inputList.every((item) =>
            isInstance<ICategory>(item, [
              'id',
              'name',
              'parent_id',
              'sort',
              'seo_description',
              'seo_title',
              'slug',
              'text_content',
              'created_at',
              'updated_at'
            ])
          )
        ) {
          const response = categoriesStore.changePositions(
            inputList[removedIndex].id,
            inputList[addedIndex].id,
            { ...inputList[removedIndex], sort: inputList[addedIndex].sort },
            { ...inputList[addedIndex], sort: inputList[removedIndex].sort }
          );

          response.then((val) => {
            notifyUser(val.msg, val.isOk ? 'success' : 'error');

            if (val.isOk) {
              setInputList((arr) => {
                return arrayMoveImmutable(arr, removedIndex, addedIndex);
              });
            }
          });
        } else {
          const response = productsStore.changePositions(
            inputList[removedIndex].id,
            inputList[addedIndex].id,
            {
              ...inputList[removedIndex],
              sort: inputList[addedIndex].sort,
              price: 0,
              variations: []
            },
            {
              ...inputList[addedIndex],
              sort: inputList[removedIndex].sort,
              price: 0,
              variations: []
            }
          );

          response.then((val) => {
            notifyUser(val.msg, val.isOk ? 'success' : 'error');

            if (val.isOk) {
              setInputList((arr) => {
                return arrayMoveImmutable(arr, removedIndex, addedIndex);
              });
            }
          });
        }
      }
    },
    [productsStore, notifyUser, path, isSortable, inputList, setInputList]
  );

  useEffect(() => {
    if (sectionId) {
      const resp = infoSectionStore.getInfoSection(sectionId);

      resp.then((val) => {
        if (val) {
          setParentSectionSlug(infoSection.slug || '');
        }
      });
    }
  }, [sectionId]);

  const moveToPage = useCallback(
    (productId: number, product: IProduct | ICategory) =>
      async (pageNumber: number) => {
        try {
          const response = await api.products.getProductsList(
            pageNumber,
            true,
            20
          );

          if (response.status === 200) {
            const data = isPaginated(response.data)
              ? response.data.data
              : response.data;

            const sortParam = data[0].sort + 1;

            if (
              isInstance<IProduct>(product, [
                'id',
                'name',
                'price',
                'sort',
                'seo_description',
                'seo_title',
                'slug',
                'text_content',
                'variations',
                'created_at',
                'updated_at'
              ])
            ) {
              const newResponse = productsStore.editProduct(productId, {
                ...product,
                sort: sortParam
              });

              newResponse.then((val) => {
                if (!val.isOk) {
                  notifyUser(val.msg, 'error');
                } else {
                  notifyUser(
                    `Товар успешно перемещён на страницу ${pageNumber}`,
                    'success'
                  );
                  onReload();
                }
              });
            } else if (
              isInstance<ICategory>(product, [
                'id',
                'name',
                'parent_id',
                'sort',
                'seo_description',
                'seo_title',
                'slug',
                'text_content',
                'created_at',
                'updated_at'
              ])
            ) {
              const newResponse = categoriesStore.editCategory(productId, {
                ...product,
                sort: sortParam
              });

              newResponse.then((val) => {
                if (!val.isOk) {
                  notifyUser(val.msg, 'error');
                } else {
                  notifyUser(
                    `Категория перемещена на страницу ${pageNumber}`,
                    'success'
                  );
                  onReload();
                }
              });
            }
          } else {
            notifyUser('Невозможно загрузить список товаров', 'error');
          }
        } catch (error) {
          notifyUser('Невозможно загрузить список товаров', 'error');
        }
      },
    []
  );

  const onDeleteClick = useCallback(
    (id: number) => () => {
      setDeleteDialogId(id);
    },
    [setDeleteDialogId]
  );

  const onDeleteDialogDelete = useCallback(() => {
    if (deleteDialogId) {
      switch (path) {
        case EnumPath.categories: {
          const response = categoriesStore.deleteCategory(deleteDialogId);

          response.then((val) => {
            onReload();

            notifyUser(val.msg, val.isOk ? 'warning' : 'error');
          });
          break;
        }
        case EnumPath.products: {
          const response = productsStore.deleteProduct(deleteDialogId);

          response.then((val) => {
            onReload();

            notifyUser(val.msg, val.isOk ? 'warning' : 'error');
          });
          break;
        }
        case EnumPath.tags: {
          const response = tagsStore.deleteTag(deleteDialogId);

          response.then((val) => {
            onReload();

            notifyUser(val.msg, val.isOk ? 'warning' : 'error');
          });
          break;
        }
        case EnumPath.infosections: {
          const response = infoSectionStore.deleteInfoSection(deleteDialogId);

          response.then((val) => {
            onReload();

            notifyUser(val.msg, val.isOk ? 'warning' : 'error');
          });
          break;
        }
        case EnumPath.infopages: {
          const response = infoPagesStore.deleteInfoPage(deleteDialogId);

          response.then((val) => {
            onReload();

            notifyUser(val.msg, val.isOk ? 'warning' : 'error');
          });
          break;
        }
        case EnumPath.seo: {
          const response = seoStore.deleteSeo(deleteDialogId);

          response.then((val) => {
            onReload();

            notifyUser(val.msg, val.isOk ? 'warning' : 'error');
          });
          break;
        }
        default: {
          break;
        }
      }
    }

    setDeleteDialogId(null);
  }, [deleteDialogId, setDeleteDialogId, onReload]);

  const onDeleteDialogClose = useCallback(() => {
    setDeleteDialogId(null);
  }, [setDeleteDialogId]);

  const onItemClick = useCallback(
    (id: number) => () => {
      navigate(`${location.pathname}/${id}`);
    },
    [navigate, location]
  );

  const printSecondaryText = (
    elem: IProduct | ITag | ICategory | IInfoSection | IInfoPage
  ): string => {
    if ('price' in elem) {
      return elem.price.toString();
    }
    if ('section_id' in elem) {
      return `${parentSectionSlug}/${elem.slug}`;
    }
    return '';
  };

  return (
    <Grid item xs={12} md={12}>
      <List dense={false} sx={{ padding: 0 }}>
        <Container dragHandleSelector=".drag-handle" onDrop={onDrop}>
          {inputList.map((elem) => {
            return (
              <Draggable key={elem.id}>
                <ListItem
                  sx={{
                    paddingLeft: 0,
                    paddingBottom: 0,
                    paddingTop: 0
                  }}
                  secondaryAction={
                    <Stack display="flex" flexDirection="row">
                      {isSortable && (
                        <IconButton
                          edge="start"
                          aria-label="drag-handle"
                          className="drag-handle"
                        >
                          <ExpandIcon />
                        </IconButton>
                      )}
                      {isSortable && (
                        <IconButton
                          sx={{ width: '40px' }}
                          onClick={(
                            event: React.MouseEvent<
                              HTMLButtonElement,
                              globalThis.MouseEvent
                            >
                          ) => {
                            setProductToMove(elem);
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          <MoveDownIcon />
                        </IconButton>
                      )}
                      <IconButton onClick={onDeleteClick(elem.id)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                  }
                >
                  <ListItemButton
                    onClick={onClick ? onClick(elem.id) : onItemClick(elem.id)}
                  >
                    <ListItemText
                      primary={
                        (elem.name ?? elem.url) +
                        ('parent_id' in elem && elem.parent_id
                          ? ` parent: ${elem.parent_id}`
                          : '')
                      }
                      secondary={printSecondaryText(elem)}
                    />
                  </ListItemButton>
                  <DialogModal
                    open={deleteDialogId === elem.id}
                    title={`Удалить "${elem.name}"?`}
                    text="Это действие необратимо"
                    button1Text="Удалить"
                    button1Type="error"
                    button2Text="Отменить"
                    onClose={onDeleteDialogClose}
                    on1ButtonClick={onDeleteDialogDelete}
                    on2ButtonClick={onDeleteDialogClose}
                  />
                </ListItem>
                <Divider />
              </Draggable>
            );
          })}
          <MoveToPageMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            onPageClick={moveToPage(productToMove?.id || 0, productToMove)}
            pagesQuantity={pagesQuantity || 1}
          />
        </Container>
      </List>
    </Grid>
  );
};

export default memo(DraggableList);
