import React, { FC } from 'react';
import { Paper, Box, ThemeProvider } from '@mui/material';
import { useSanctum } from 'react-sanctum';

import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';

import SidebarMenu from 'components/sidebarMenu';
import TitleBar from 'components/titleBar';

import MainPage from 'routes/mainPage';
import Authorization from 'routes/auth';
import Products from 'routes/catalog/products';
import Product from 'routes/catalog/products/product';
import Categories from 'routes/catalog/categories';
import Category from 'routes/catalog/categories/category';
import Tags from 'routes/catalog/tags';
import Tag from 'routes/catalog/tags/tag';
import Pages from 'routes/content/pages';

import mainTheme from 'themes';
import ErrorPage from 'routes/404';

import './app.scss';
import Seo from 'routes/system/Seo';
import InfoSections from 'routes/system/infoSections';
import InfoSection from 'routes/system/infoSections/infoSection';
import Page from 'routes/content/pages/page';
import Downloads from 'routes/catalog/downloads';
import SeoTag from './routes/system/Seo/SeoTag';

const App: FC = () => {
  const { authenticated } = useSanctum();

  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          path: '*',
          element: <ErrorPage />,
          children: []
        },
        {
          index: true,
          element: <MainPage />
        },
        {
          path: '/products',
          element: <Products />,
          children: []
        },
        {
          path: '/products/:id',
          element: <Product />,
          children: []
        },
        {
          path: '/categories',
          element: <Categories />,
          children: []
        },
        {
          path: '/categories/:id',
          element: <Category />,
          children: []
        },
        {
          path: '/tags',
          element: <Tags />,
          children: []
        },
        {
          path: '/tags/:id',
          element: <Tag />,
          children: []
        },
        {
          path: '/tools',
          element: <Downloads />,
          children: []
        },
        {
          path: '/pages-from-infosection/:id',
          element: <Pages />,
          children: []
        },
        {
          path: '/info-pages/:id',
          element: <Page />,
          children: []
        },
        {
          path: '/sections',
          element: <InfoSections />,
          children: []
        },
        {
          path: '/sections/:id',
          element: <InfoSection />,
          children: []
        },
        {
          path: '/seo',
          element: <Seo />,
          children: []
        },
        {
          path: '/seo/:id',
          element: <SeoTag />,
          children: []
        }
      ]
    }
  ];

  const element = useRoutes(routes);

  return (
    <ThemeProvider theme={mainTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <TitleBar />
        <Paper
          elevation={0}
          square
          sx={{
            height: 'calc(100vh - 64px)',
            width: '100%',
            display: 'flex'
          }}
        >
          {!authenticated ? (
            <Authorization />
          ) : (
            <>
              <SidebarMenu />
              {element}
            </>
          )}
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default observer(App);
