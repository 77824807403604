import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import seoStore from '../../../store/seoStore';
import globalState from '../../../store/globalState';
import Notification from '../../../components/notification';

import DraggableList from '../../../components/draggableList';
import { EnumPath } from '../../../types/types';
import Loader from '../../../components/loader';
import AddSeo from '../../../components/modals/addSeo';

const Seo: FC = () => {
  const { seos, loading } = seoStore;

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] = useState<
    'error' | 'success' | 'warning'
  >('error');
  const [notificationMsg, setNotificationMsg] = useState<string>('');

  const notifyUser = useCallback(
    (message: string, status: 'error' | 'success') => {
      setNotificationMsg(message);
      setNotificationStatus(status);
      setIsNotificationOpen(true);
    },
    [setNotificationMsg, setNotificationStatus, setIsNotificationOpen]
  );

  const reload = useCallback(() => {
    const response = seoStore.getSeos();

    response.then((val) => {
      if (!val.isOk) {
        notifyUser(val.msg, 'error');
      }
    });
  }, []);

  useEffect(() => {
    reload();

    globalState.setNewPathname('Сео');
    globalState.setRefreshFunction(seoStore.getSeos, {});
  }, []);

  const onSeoAdd = (
    url: string,
    meta_title: string,
    meta_description: string,
    meta_keywords: string,
    text: string
  ) => {
    const response = seoStore.addSeo({
      url,
      meta_title,
      meta_description,
      meta_keywords,
      text
    });

    response.then((val) => {
      notifyUser(val.msg, val.isOk ? 'success' : 'error');

      reload();
    });
  };

  return (
    <Grid
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'scroll'
      }}
      item
      xs={12}
      md={12}
    >
      <Notification
        open={isNotificationOpen}
        message={notificationMsg}
        onClose={() => {
          setIsNotificationOpen(false);
          setNotificationMsg('');
        }}
        severity={notificationStatus}
      />
      <AddSeo
        isOpen={modalIsOpen}
        notifyUser={notifyUser}
        setIsOpen={setModalIsOpen}
        onSeoAdd={onSeoAdd}
      />
      {!loading ? (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'primary',
              width: 'auto',
              height: '48px'
            }}
          >
            <Typography variant="h6" sx={{ pl: 2 }}>
              Название
            </Typography>

            <Stack flexDirection="row">
              <IconButton onClick={() => setModalIsOpen(true)}>
                <AddCircleOutlineOutlined color="primary" />
              </IconButton>
              <Button variant="text" onClick={() => setModalIsOpen(true)}>
                Создать
              </Button>
            </Stack>
          </Box>
          <DraggableList
            list={seos}
            pagesQuantity={1}
            notifyUser={notifyUser}
            onReload={reload}
            path={EnumPath.seo}
          />
        </Box>
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

export default observer(Seo);
