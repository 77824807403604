export interface IProps {
  page?: number;
  paginate?: boolean;
  perpage?: number;
  orderBy?: string;
  whereIn?: string;
}

export interface IMedia {
  id: number;
  path: string;
  sort?: number | null;
  created_at?: Date;
  updated_at?: Date;
}

export interface IMediaData {
  main?: Array<IMedia>;
  gallery?: Array<IMedia>;
}

export interface IProduct {
  id: number;
  name: string;
  price: number;
  sort: number;
  priceInvalid?: boolean;
  seo_description?: null | string;
  seo_title?: null | string;
  slug?: null | string;
  text_content?: null | string;
  variations: Array<IProductVariation>;
  created_at?: Date;
  updated_at?: Date;
}

export interface IAddProduct {
  id?: number;
  name: string;
  price: number;
  sort?: number;
  priceInvalid?: boolean;
  seo_description?: null | string;
  seo_title?: null | string;
  slug?: null | string;
  text_content?: null | string;
  variations?: Array<IProductVariation>;
  created_at?: Date;
  updated_at?: Date;
}

export interface IProductVariation {
  id?: number;
  price?: number;
  discount_price?: number | null;
  is_base?: boolean | null;
  is_bestseller?: boolean | null;
  is_product_of_the_day?: boolean | null;
  is_new?: boolean | null;
  name: string;
  sku: string | null;
  text_content?: string | null;
  seo_title?: string | null;
  seo_description?: string | null;
  slug?: string | null;
  product_variation_container_id: number;
}

export interface IProductItem extends IProduct {
  categories: Array<ICategory>;
  tags: Array<ITag>;
}

export interface ICharacteristic {
  characteristic_type_id: number;
  id?: number;
  value: string;
}

export interface ICharacteristicTypeShort {
  id?: number;
  name: string;
}

export interface ICharacteristicType {
  id?: number;
  name: string;
  values: Array<ICharacteristicValue>;
}

export interface ICharacteristicValue {
  characteristic_type_id: number;
  id?: number;
  value: string;
  slug?: null | string;
  custom_title?: null | string;
  custom_description?: null | string;
  text_content?: null | string;
}

export interface ICharacteristicFull {
  id: number;
  value: string;
  characteristic_type_id: number;
  slug: string | null;
  text_content: string | null;
  type: ICharacteristicTypeShort;
}

export interface IPartialCategoryItem {
  id: number;
  name?: string;
  alternative_name?: null | string;
  sort?: number;
  parent_id?: number | null;
  seo_description?: null | string;
  seo_description_template?: null | string;
  seo_title?: null | string;
  seo_title_template?: null | string;
  description_template?: null | string;
  slug?: null | string;
  text_content?: null | string;
  created_at?: Date;
  updated_at?: Date;
  applicable_characteristic_types: Array<ICharacteristicTypeShort>;
  applicable_characteristic_types_for_filter: Array<ICharacteristicTypeShort>;
  applicable_characteristic_types_for_list: Array<ICharacteristicTypeShort>;
  applicable_tags: Array<Partial<ITag>>;
  children: Array<ICategory>;
  containers?: Array<IAddProduct>;
}

export interface IPartialTagItem {
  id: number;
  name: string;
  main_category?: null | number;
  seo_description?: null | string;
  seo_title?: null | string;
  slug?: null | string;
  text_content?: null | string;
  created_at?: Date;
  updated_at?: Date;
  containers: Array<IAddProduct>;
}

export interface IVariation {
  characteristicValues: Array<ICharacteristic>;
  container?: IProduct;
  id: number;
  price?: number;
  is_bestseller?: boolean | null;
  is_product_of_the_day?: boolean | null;
  is_new?: boolean | null;
  discount_price?: number | null;
  is_base?: boolean | null;
  sku: string | null;
  text_content?: string | null;
  name: string;
  seo_description?: null | string;
  seo_title?: null | string;
  slug?: null | string;
  product_variation_container_id: number;
}

export interface ITag {
  id: number;
  name: string;
  seo_description: null | string;
  seo_title: null | string;
  main_category?: null | number;
  slug: null | string;
  text_content: null | string;
  created_at: Date;
  updated_at: Date;
}

export interface ITagItem extends ITag {
  containers: Array<IProduct>;
  categories?: Array<ICategory>;
}

export interface ICategory {
  id: number;
  name: string;
  alternative_name: null | string;
  parent_id: number | null;
  sort: number;
  seo_description: null | string;
  seo_title: null | string;
  seo_description_template: null | string;
  seo_title_template: null | string;
  description_template: null | string;
  slug: null | string;
  text_content: null | string;
  created_at: Date;
  updated_at: Date;
}

export interface ICategoryItem extends ICategory {
  applicable_characteristic_types: Array<ICharacteristicTypeShort>;
  applicable_characteristic_types_for_filter: Array<ICharacteristicTypeShort>;
  applicable_characteristic_types_for_list: Array<ICharacteristicTypeShort>;
  applicable_tags: Array<ITag>;
  children: Array<ICategory>;
  containers: Array<IProduct>;
}

export type ListType<T> = Array<T>;

export interface IPaginationResponse<P> {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
  data: ListType<P>;
}

export enum EnumPath {
  products = 0,
  categories = 1,
  tags = 2,
  infosections = 3,
  infopages = 4,
  seo = 5
}

export interface ISeo {
  id: number;
  url: string;
  meta_title: string;
  meta_description: string;
  meta_keywords: string;
  text: string;
}

export interface ISeoAdd {
  id?: number;
  url: string;
  meta_title?: string;
  meta_description?: string;
  meta_keywords?: string;
  text?: string;
}

export interface IInfoSection {
  id: number;
  name: string;
  description: string;
  section_template: string;
  element_template: string;
  seo_description: null | string;
  seo_title: null | string;
  slug: null | string;
  text_content: null | string;
  created_at?: Date;
  updated_at?: Date;
}

export interface IInfoSectionAdd {
  id?: number;
  name: string;
  description: string;
  section_template: string;
  element_template: string;
  seo_description?: null | string;
  seo_title?: null | string;
  slug?: null | string;
  text_content?: null | string;
  created_at?: Date;
  updated_at?: Date;
}

export interface IInfoPage {
  id: number;
  name: string;
  description: string;
  section_id: number;
  seo_description: null | string;
  seo_title: null | string;
  slug: null | string;
  text_content: null | string;
  created_at?: Date;
  updated_at?: Date;
}

export interface IInfoPageAdd {
  id?: number;
  name: string;
  description: string;
  section_id: number;
  seo_description?: null | string;
  seo_title?: null | string;
  slug?: null | string;
  text_content?: null | string;
  created_at?: Date;
  updated_at?: Date;
}
