import products from 'api/products';
import categories from './categories';
import tags from './tags';
import variations from './variations';
import media from './media';
import characteristics from './characteristics';
import infoSections from './infoSections';
import infoPages from './infoPages';
import downloads from './downloads';
import seo from './seo';

export default {
  products,
  categories,
  tags,
  variations,
  media,
  characteristics,
  infoSections,
  infoPages,
  downloads,
  seo
};
