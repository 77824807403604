import axios from 'api/helpers/axios';
import { ISeo, ISeoAdd } from 'types/types';

interface IResponse<T> {
  data: T;
  status?: number;
  statusText?: string;
  message?: string;
  name?: string;
}

export default {
  async getSeos(): Promise<IResponse<Array<ISeo>>> {
    const response = await axios.get('/seo');

    return response;
  },
  async getSeo(id: number): Promise<IResponse<ISeo>> {
    const response = await axios.get(`/seo/${id}`);

    return response;
  },

  async deleteSeo(id: number): Promise<IResponse<unknown>> {
    const response = await axios.delete(`/seo/${id}`);

    return response;
  },

  async addSeo(data: ISeoAdd): Promise<IResponse<unknown>> {
    const response = await axios.post(`/seo`, {
      ...data
    });

    return response;
  },

  async editSeo(id: number, data: ISeoAdd): Promise<IResponse<ISeo>> {
    const response = await axios.patch(`/seo/${id}`, {
      ...data
    });

    return response;
  }
};
