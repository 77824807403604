import React, { FC, useCallback, useState, ChangeEvent } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Stack,
  TextField,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Editor from '../../editor/editor';

interface IProps {
  isOpen: boolean;
  setIsOpen(val: boolean): void;
  notifyUser(
    message: string,
    status: 'error' | 'success' | 'info' | 'warning'
  ): void;
  onSeoAdd(
    url: string,
    meta_title: string,
    meta_description: string,
    meta_keywords: string,
    text: string
  ): void;
}

const AddSeo: FC<IProps> = ({ isOpen, setIsOpen, onSeoAdd, notifyUser }) => {
  const [url, setUrl] = useState<string>('');
  const [metaTitle, setMetaTitle] = useState<string>('');
  const [metaDescription, setMetaDescription] = useState<string>('');
  const [metaKeywords, setMetaKeywords] = useState<string>('');
  const [text, setText] = useState<string>('');

  const handleModalClose = useCallback(() => {
    setUrl('');
    setMetaTitle('');
    setMetaDescription('');
    setMetaKeywords('');
    setText('');
    setIsOpen(false);
  }, []);

  const handleSeoAdd = useCallback(() => {
    onSeoAdd(url, metaTitle, metaDescription, metaKeywords, text);
    setUrl('');
    setMetaTitle('');
    setMetaDescription('');
    setMetaKeywords('');
    setText('');
    setIsOpen(false);
  }, [url, metaTitle, metaDescription, metaKeywords, text, onSeoAdd]);

  const onUrlChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUrl(event.currentTarget.value);
    },
    []
  );

  const onMetaTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMetaTitle(event.currentTarget.value);
    },
    []
  );

  const onMetaDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMetaDescription(event.currentTarget.value);
    },
    []
  );

  const onMetaKeywordsChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMetaKeywords(event.currentTarget.value);
    },
    []
  );

  const onTextChange = useCallback(
    (val: string) => {
      setText(val);
    },
    [setText]
  );

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1200,
          bgcolor: 'background.paper',
          boxShadow: 24,
          color: 'text.primary',
          p: 1
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleModalClose}>
            <CloseIcon
              sx={(theme) => ({
                '&:hover': {
                  color: theme.palette.error.main
                }
              })}
            />
          </IconButton>
        </Box>
        <Box sx={{ p: 3, pt: 0 }}>
          <Typography variant="h6">Добавление инфосекции</Typography>
          <TextField
            fullWidth
            multiline
            error={!url}
            value={url}
            onChange={onUrlChange}
            label="Адрес"
            helperText={
              !url && 'Введите название адрес страницы /catalog/example'
            }
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <TextField
            fullWidth
            multiline
            // error={!metaTitle}
            value={metaTitle}
            onChange={onMetaTitleChange}
            label="Meta title"
            helperText={!metaTitle && 'Введите meta title'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          <TextField
            fullWidth
            multiline
            // error={!metaDescription}
            value={metaDescription}
            onChange={onMetaDescriptionChange}
            label="Meta description"
            helperText={!metaDescription && 'Введите meta description'}
            variant="filled"
            sx={{ mt: '15px' }}
          />
          {/* <TextField
            fullWidth
            multiline
            // error={!metaKeywords}
            value={metaKeywords}
            onChange={onMetaKeywordsChange}
            label="Meta keywords"
            helperText={!metaKeywords && 'Введите meta keywords'}
            variant="filled"
            sx={{ mt: '15px' }}
          /> */}
          <Editor
            id="id"
            value={text}
            notifyUser={notifyUser}
            onEditorChange={onTextChange}
          />
          {/* <TextField
            fullWidth
            multiline
            // error={!text}
            value={text}
            onChange={onTextChange}
            label="Текст"
            helperText={!text && 'Введите текст'}
            variant="filled"
            sx={{ mt: '15px' }}
          /> */}
          <Stack
            flexDirection="row"
            gap={2}
            sx={{ pt: 5, justifyContent: 'flex-end' }}
          >
            <Button
              size="small"
              variant="outlined"
              color="success"
              disabled={!url}
              onClick={handleSeoAdd}
            >
              Добавить
            </Button>
            <Button size="small" variant="outlined" onClick={handleModalClose}>
              Отменить
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddSeo;
