// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { observable, action, makeObservable, runInAction } from 'mobx';

// API
import api from 'api';
import axios from 'axios';
import { ISeoAdd, ISeo } from 'types/types';

interface IError {
  isOk: boolean;
  msg: string;
}

const voidSeo = () => ({
  id: 0,
  url: '',
  meta_title: '',
  meta_description: '',
  meta_keywords: '',
  text: ''
});

class SeoStore {
  @observable
  loading: boolean = false;

  @observable
  seos: ISeo[] = [];

  @observable
  seo: ISeo = voidSeo();

  constructor() {
    makeObservable(this);
  }

  @action
  getSeos = async (): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const seos = await api.seo.getSeos();

      runInAction(() => {
        if (seos.status === 200) {
          this.seos = seos.data;

          res.isOk = true;
          res.msg = 'Сео теги получены';
        } else {
          console.error(
            `Error\nCode: ${seos.status}\nStatus: ${seos.statusText}`
          );

          res.isOk = false;
          res.msg = seos.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  getSeo = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const seo = await api.seo.getSeo(id);

      runInAction(() => {
        if (seo.status === 200) {
          this.seo = seo.data;

          res.isOk = true;
          res.msg = 'Сео теги получены';
        } else {
          console.error(
            `Error\nCode: ${seo.status}\nStatus: ${seo.statusText}`
          );

          res.isOk = false;
          res.msg = seo.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  addSeo = async (data: ISeoAdd): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const seo = await api.seo.addSeo(data);

      runInAction(() => {
        if (seo.status === 201) {
          res.isOk = true;
          res.msg = 'Сео теги добавлены';
        } else {
          console.error(
            `Error\nCode: ${seo.status}\nStatus: ${seo.statusText}`
          );

          res.isOk = false;
          res.msg = seo.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  deleteSeo = async (id: number): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const seo = await api.seo.deleteSeo(id);

      runInAction(() => {
        if (seo.status === 200 || seo.status === 204) {
          res.isOk = true;
          res.msg = 'Инфосекция удалена';
        } else {
          console.error(
            `Error\nCode: ${seo.status}\nStatus: ${seo.statusText}`
          );
          res.isOk = false;
          res.msg = seo.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };

  @action
  editSeo = async (id: number, data: ISeoAdd): Promise<IError> => {
    this.loading = true;

    const res = {
      isOk: false,
      msg: ''
    };

    try {
      const seo = await api.seo.editSeo(id, data);

      runInAction(() => {
        if (seo.status === 200) {
          res.isOk = true;
          res.msg = 'Сео теги изменены';

          const newSectionId = this.seos.findIndex((el) => el.id === id);

          if (newSectionId !== -1) {
            this.seos[newSectionId] = seo.data;
          }
        } else {
          console.error(
            `Error\nCode: ${seo.status}\nStatus: ${seo.statusText}`
          );

          res.isOk = false;
          res.msg = seo.statusText || '';
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        res.msg = `${error.message}:\n ${error.response?.data.message}`;
      }

      this.loading = false;
      res.isOk = false;

      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return res;
  };
}

const seoStore = new SeoStore();
export default seoStore;
